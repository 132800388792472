<template>
  <div class="app-container">
    <!-- <div style="margin-bottom: 10px;">
            <el-button icon="el-icon-plus" size="mini" @click="syncDimension()">
                {{$t('dimension.syncDimension')}}
            </el-button>
        </div> -->
    <el-table
      v-loading="loading"
      :data="dimensionList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :label="$t('commons.index')"
        width="95"
      >
      </el-table-column>
      <el-table-column align="center" :label="$t('dimension.code')" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.dimensionCode }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="dimensionName"
        :label="$t('dimension.name')"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.dimensionName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dimensionUrl" :label="$t('dimension.url')">
        <template slot-scope="scope">
          <span>{{ scope.row.dimensionUrl }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="dimensionType"
        :label="$t('dimension.type')"
        width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.dimensionTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operation"
        :label="$t('commons.lastUpdateDate')"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>
            {{
              scope.row.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/dimension");
import { serverHost } from "@/constants/envInfo";

export default {
  name: "DimensionList",
  data() {
    return {
      dimensionList: null,
      loading: false,
      options: {
        type: [
          { value: 0, label: this.$t("dimension.general") },
          { value: 1, label: this.$t("dimension.personnel") },
        ],
      },
    };
  },
  created() {
    this.getDimensionList();
  },
  methods: {
    // syncDimension() {
    //     this.loading = true
    //     manager.syncDimension().then(()=>{
    //         this.getDimensionList();
    //         this.loading = false;
    //     }).catch(()=> {
    //         this.loading = false;
    //     })
    // },
    getDimensionList() {
      this.loading = true;
      manager
        .getDimensionList()
        .then((response) => {
          response.map((v) => {
            v.dimensionUrl = `${serverHost}${v.dimensionUrl}`;
            v.dimensionTypeName = this.getTypeDisplay(v.dimensionType);
            v.dimensionName = this.$t(v.dimensionName);
          });
          this.dimensionList = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    getTypeDisplay(type) {
      if (type == 0) {
        return this.$t("dimension.general");
      } else {
        return this.$t("dimension.personnel");
      }
    },
  },
};
</script>
