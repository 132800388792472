import request from '@/utils/request'
import { envInfo, serverHost } from '@/constants/envInfo'

//获取维度集合
export function getDimensionList() {
    return request({
      url: envInfo.bgApp.securityPath + '/dimension/list',
      method: 'get'
    })
}

//请求定制维度
export function reqConsumeDimension(service, params) {
    return request({
      url: `${serverHost+service}`,
      method: 'get',
      params
    })
}

///////////////================系统管理服务
//请求选择的维度
export function _sys_getCheckDimension(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/program/findProgramItem',
      method: 'get',
      params
    })
}

//请求选择的维度All标记
export function _sys_getCheckAllDimension(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/program/findProgramItemAll',
    method: 'get',
    params
  })
}


//创建数据权限维度关系
export function _sys_createProgramDimension(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/program/createProgramDimension',
      method: 'put',
      data: params
    })
}

//删除数据权限维度关系
export function _sys_deleteProgramDimension(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/program/deleteProgramDimension',
      method: 'put',
      data: params
    })
}

///////////////================租户管理服务

export function _tenant_getCheckDimension(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/findProgramItem',
    method: 'get',
    params
  })
}

//请求选择的维度All标记
export function _tenant_getCheckAllDimension(params) {
return request({
  url: envInfo.bgApp.securityPath + '/tenant/program/findProgramItemAll',
  method: 'get',
  params
})
}


//创建数据权限维度关系
export function _tenant_createProgramDimension(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/createProgramDimension',
    method: 'put',
    data: params
  })
}

//删除数据权限维度关系
export function _tenant_deleteProgramDimension(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/program/deleteProgramDimension',
    method: 'put',
    data: params
  })
}

