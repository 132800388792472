var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.dimensionList,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              label: _vm.$t("commons.index"),
              width: "95",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("dimension.code"),
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.dimensionCode))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dimensionName",
              label: _vm.$t("dimension.name"),
              width: "110",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.dimensionName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "dimensionUrl", label: _vm.$t("dimension.url") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.dimensionUrl))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dimensionType",
              label: _vm.$t("dimension.type"),
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.dimensionTypeName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operation",
              label: _vm.$t("commons.lastUpdateDate"),
              width: "200",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              scope.row.lastUpdateDate,
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }